//customsProcedureMatrixMeasureService
module services {
    export module tariff {
        export class customsProcedureMatrixMeasureService implements interfaces.tariff.ICustomsProcedureMatrixMeasureService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }
            
            getDropdownList(countryId: number, procedureCategoryId: number, customsProcedureId?: number, prevCustomsProcedureId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomsProcedureMatrixMeasure/GetDropdownList", {
                    countryId: countryId,
                    procedureCategoryId: procedureCategoryId,
                    customsProcedureId: customsProcedureId,
                    prevCustomsProcedureId: prevCustomsProcedureId
                });
            }
        }
    }
    angular.module("app").service("customsProcedureMatrixMeasureService", services.tariff.customsProcedureMatrixMeasureService);
}